import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "use-cases"
    }}>{`Use Cases`}</h1>
    <h3 {...{
      "id": "identify-spear-phishing"
    }}>{`Identify Spear Phishing`}</h3>
    <p>{`Everyday many people lose control of their email accounts.  Weak passwords, password reuse, and falling for scams are the common ways email accounts are compromised.`}</p>
    <p>{`If an email account is compromised, an attractively baited email, such as one with fake bank website login credentials, will attract a threat actor during their reconnaissance phase.  When the email is accessed, your security team will be notified and you can deactivate the account and change the password before any real damage is done.`}</p>
    <h3 {...{
      "id": "application-and-database-servers"
    }}>{`Application and Database Servers`}</h3>
    <p>{`Threat actors look for ways to escalate their access.  If they've gained access to a server, their next move will be to escalate their privileges and to gain access to sensitive data. A Word or PDF file placed on the server titled, "Admin credentials for this database," or something to that effect, is irresistible.  When the document is opened, you'll know that server is under attack.  If your server is in a Windows environment, you can even determine which account was compromised to gain access to the server.`}</p>
    <h3 {...{
      "id": "sensitive-data-and-insider-threat"
    }}>{`Sensitive Data and Insider Threat`}</h3>
    <p>{`Insiders can pose a significant risk to an organization.  If you want to know who your nosey and potentially risky employees are, you can create a fake payroll document on the network and embed that document in a Windows folder with a security token. Bury the folder deep in the network so that it would only be accessed by someone through a deliberate search.  If it is accessed, the Windows folder will tell you the identity, and the document token will tell you they opened the file.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      